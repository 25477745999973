table {
    border: none !important;
}

.table {
    margin-bottom: 0;
}

table th,
table tr td {
    color: var(--white) !important;
    border: none !important;
}

table th:first-of-type {
    width: 50%;
}

table thead {
    background-color: var(--table-gray-first);
    font-family: poppins-semibold, poppins, sans-serif;
    font-size: 1.375rem;
}

.table thead th {
    vertical-align: top;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: var(--table-gray-second);
}

.table-striped tbody tr:nth-of-type(2n) {
    background-color: var(--table-gray-first);
}

.table > tbody > tr > td > div > a {
    color: var(--white);
}

td button {
    margin: 0 0.25rem;
}

.col {
    padding: 0;
}

.table-header-appendix {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
}

.table-search {
    height: 2.5rem !important;
    font-family: Roboto, sans-serif;
}