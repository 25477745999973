@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	/* outline: 1px solid rgb(255, 157, 0) !important; */
	user-select: text;
}

*,
::after,
::before {
	box-sizing: border-box;
	background-repeat: no-repeat;
}

::after,
::before {
	text-decoration: inherit;
	vertical-align: inherit;
}
/* https://picocss.com/docs/ see how they imlement darkmode TODO, also other global css elements */
:root {
	--default-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
	--font-regular: var(--default-fonts);
	--font-adelez: Makasih, -apple-system, 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', sans-serif;

	/* --divider-size: 10vh; */
	--divider-size: 70px; /*in tailwind config as: divider */
	--nav-height: 70px; /*in tailwind config as: navHeight */
	--hero-height: 90vh;
	/* --sideDistance: 10px; */
	/* --content-max-width: 700px; */
	--c-text: #000;
	--bg-main: #f5efef; /*in tailwind config as: main */
	--c-bg-black: #17151f;
	/* 
    #40778b; 
    #4caf50
    */
	--c-cta: #22c55e;

	--c-red-orange: #de654f;
	/* following was e3d6d1 */
	--c-offwhite: #e4d6d2;
	/* bg-blue-900 */
	/* --c-doodle: rgb(0 142 215); */
	--c-doodle: #40778b;
	/* var(--c-cta); */
	--c-doodle-contact: #2f0c0a;
	--c-red: #e11d48;
	--c-green: #22c55e;
	--c-beige: #d9c7ab;
	--btn-color: var(--c-doodle);

	/* should be final colors */
	/* Main BG (not including contact and special sections) */
	--c-bg_main: #f7f3ee; /*beige*/
	/* Specials BG, Headers (on Main BG), behind FAQ boxes, Testimony parts (arrows, name), 1 sentance in About section (TBD) */
	--c-orange: #df654e; /*orange*/
	/* Bottom gradient of Specials BG, Get your own in special section  */
	--c-dark_orange: #d84e33; /*dark orange*/
	/* Main doodle color, Main button color (not including contact and special sections), Tier tables SVG BG */
	--c-bluish: #3d778b; /*turqouise*/
	/* Testimonies BG, Contact BG, FAQ title, Nav Menu BG? */
	--c-bg_2: #dfd1cd; /*dark pink-beige*/
	/* Logo (on light BG’s), text in Nav Menu, About, Gallery, Testimonies, Tiers, FAQ */
	--c-black: #000; /*black*/
	/* Logo (on dark BG’s), Hero SVG title, Gallery Titles (non-selected in 0.7-0.8 opacity), Main button text color, Special title and text */
	--c-white: #fff; /*white*/
	/* tiers list checkmarks */
	--c-green: #84c44d; /*green*/
	/* Contact text and doodle */
	--c-contact: #2f0c0a; /*dark bordeaux*/

	/* TODO check if this can be removed, i dont think i use it anymore */
	/* --scroll: 0; */

	--zi-hero: 5;
	--zi-hero-divider: 10;
	--zi-divider: 10;
	--zi-about: 9;
	--zi-nav: 15; /*in tailwind config as: nav */
	--zi-nav-items: 16; /*in tailwind config as: nav-items */
	--zi-testimonies: 5;
	--zi-specials: 6;

	/* TODO check why this isnt working to overlay on nav items */
	--zi-gallery-bg: 19;
	--zi-gallery: 20;
	--zi-gallery-controls: 21;

	font-family: var(--font-regular);
	text-decoration-skip-ink: 'auto';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	caret-color: transparent;

	/* contact timeings */
	/* timing of each side,.. 
    delay to syncronize sides... 
    bottom, left, top, right */
	--timeing: 0.1s;
	--delay-1: 0.1s;
	--delay-2: 0.2s;
	--delay-3: 0.3s;
	--delay-4: 0.4s;
	/* --border-color: rgb(37 99 235); */

	/* position: relative; */
	/* overscroll-behavior-y: contain; */
	/* overflow-y: scroll; */
	/* background: var(--bg-main); */

	/* toastify colors */
	--toastify-color-light: #fff;
	--toastify-color-dark: #121212;
	--toastify-color-info: #3498db;
	--toastify-color-success: #07bc0c;
	--toastify-color-warning: #f1c40f;
	--toastify-color-error: #e74c3c;
	--toastify-color-transparent: rgba(255, 255, 255, 0.7);

	--toastify-icon-color-info: var(--toastify-color-info);
	--toastify-icon-color-success: var(--toastify-color-success);
	--toastify-icon-color-warning: var(--toastify-color-warning);
	--toastify-icon-color-error: var(--toastify-color-error);

	--toastify-toast-width: 320px;
	--toastify-toast-background: #fff;
	--toastify-toast-min-height: 64px;
	--toastify-toast-max-height: 800px;
	--toastify-font-family: sans-serif;
	--toastify-z-index: 9999;

	--toastify-text-color-light: #757575;
	--toastify-text-color-dark: #fff;

	/* //Used only for colored theme */
	--toastify-text-color-info: #fff;
	--toastify-text-color-success: #fff;
	--toastify-text-color-warning: #fff;
	--toastify-text-color-error: #fff;

	--toastify-spinner-color: #616161;
	--toastify-spinner-color-empty-area: #e0e0e0;

	/* // Used when no type is provided */
	/* // toast("**hello**") */
	/* --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); */
	--toastify-color-progress-light: linear-gradient(to right, #5ac8fa, #007aff, #5856d6);
	/* // Used when no type is provided */
	--toastify-color-progress-dark: #bb86fc;
	--toastify-color-progress-info: var(--toastify-color-info);
	--toastify-color-progress-success: var(--toastify-color-success);
	--toastify-color-progress-warning: var(--toastify-color-warning);
	--toastify-color-progress-error: var(--toastify-color-error);
	@apply bg-main;
}
:where(:root) {
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	text-size-adjust: 100%;
	background-color: var(--background-color);
	color: var(--color);
	font-weight: var(--font-weight);
	font-size: var(--font-size);
	line-height: var(--line-height);
	font-family: var(--font-regular);
	text-rendering: optimizeLegibility;
	overflow-wrap: break-word;
	cursor: default;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
}
@media (prefers-reduced-motion: no-preference) {
	* {
		scroll-behavior: smooth;
	}
}

/* general */
.adelez_font {
	font-family: var(--font-adelez);
}
.flex-to-center {
	@apply flex content-center items-center justify-center;
}
.progress {
	@apply h-1 bg-orange bg-opacity-50 fixed top-0 left-0 z-50;
	/* height: 3px; */
	/* width: var(--scroll); */
}

.noscroll {
	overflow: hidden;
}
.divider {
	z-index: var(--zi-divider);
}
.react-select__menu-list {
	overscroll-behavior: none;
}
.doodle {
	@apply w-full h-full fill-doodle pointer-events-none select-none;
	max-height: 50vh;
	/* min-height: 50px; */
}
.doodle svg {
	max-height: inherit;
}
button,
.btn {
	@apply font-adelez text-center bg-doodle outline-doodle hover:outline outline-2 hover:outline-offset-4 hover:opacity-95 rounded-sm;
}
/* outline-color: var(--c-cta); */
/* background-color: var(--c-cta); */
/* font-family: var(--font-adelez); */
h1.title {
	@apply font-adelez text-5xl text-center lg:text-left w-[100vw] text-main bg-orange pt-6;
}
/* color: var(--bg-main); */
/* background-color: var(--c-red-orange); */
/* width: 100vw; */
/* font-family: var(--font-adelez); */
/* position: relative; */
/* font-size: var(--about-title); */
/* font-size: calc(1rem + 3vw); */
/* padding: 2rem; */

#overlay {
	@apply fixed inset-0 text-offwhite;
	background-color: #161418;
	/* z-index: 999; */
}

.hover-underline-animation:after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: var(--nav-items-color);
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
	transform: scaleX(1);
	transform-origin: bottom left;
}
/* end general */
/* nav start */
#nav {
	@apply z-nav;
	--anim-duration: 360ms;
	/* z-index: var(--zi-nav); */
	transition: all var(--anim-duration);
	/* --nav-items-color: #000; */
	/* height: var(--nav-height); */
	/* width: 100vw; */

	/* display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	align-self: center; */

	/* height: 50px; */
	/* padding: 1em; */

	/* display: grid;
	grid-gap: 1rem; */
	/* grid-template-columns: auto 1fr auto; */
	/* grid-template-rows: auto 1fr auto; */
	/* grid-template-areas:'logo links menu'; */
	/* justify-content: space-between;
	place-content: center; */
}
#nav > div {
	/* z-index: var(--zi-nav); */
	/* transition: all var(--anim-duration); */
	/* height: var(--nav-height); */
	--nav-items-color: #000;
	/* width: 100vw; */
	/* place-content: center; */

	/* display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	align-self: center; */

	/* height: 50px; */
	/* padding: 1em; */
	/* grid-template-columns: auto 1fr auto;
	grid-template-areas: 'logo links menu'; */

	/* display: grid; */
	/* grid-gap: 1rem; */
	/* grid-template-rows: auto 1fr auto; */
	/* justify-content: space-between; */
}
[data-section='Hero'] #nav *,
[data-section='About'] #nav *,
[data-section='Specials'] #nav * {
	--nav-items-color: #fff;
}
#nav.toggled * {
	--nav-items-color: #000;
}
#nav .logo {
	z-index: var(--zi-nav-items);
	/* transition: all var(--anim-duration); */
	color: var(--nav-items-color);
}
#nav .logo,
#nav .logo svg {
	height: 100%;
}

/* [data-section='Hero'] #nav .logo,
[data-section='Hero'] #nav .toggle,
[data-section='Specials'] #nav .logo,
[data-section='Specials'] #nav .toggle {
	color: #fff;
} */

/* [data-section='About'] #nav .logo,
[data-section='About'] #nav .toggle {
	color: #000;
} */

/* #nav .toggle > div {
	width: inherit;
	border-bottom: 4px solid;
	transition: transform 0.2s, opacity 0.2s;
} */

.menu,
.menu::before,
.menu::after {
	@apply block absolute bg-nav;
	/* background-color: var(--nav-items-color); */
	z-index: var(--zi-nav-items);
	height: 4px;
	width: 30px;
	transition: transform 400ms cubic-bezier(0.23, 0.6, 0.32, 1);
	border-radius: 2px;
}
.as_xMark .menu {
	background-color: transparent;
}
.as_xMark .menu::before {
	margin-top: 0px;
	transform: rotate(225deg);
	background-color: var(--nav-items-color);
}
.as_xMark .menu::after {
	margin-top: 0px;
	transform: rotate(-225deg);
	background-color: var(--nav-items-color);
}

#overlay .menu::before,
#overlay .menu::after {
	background-color: var(--c-red-orange);
}

#nav .links {
	background: var(--bg-main);
	@apply w-[100vw] py-[var(--nav-height)] 
    text-center inline-block 
    /* items-end  */
    absolute top-0 left-0 right-0 
    min-h-screen
    justify-end 
    md:justify-center
    md:relative md:flex md:flex-row 
    md:p-0 
    md:min-h-0 md:h-auto md:bg-transparent
    md:ml-auto 
    md:mr-4;

	/* @apply w-[100vw] py-[var(--nav-height)] 
    text-center inline-block 
    items-center 
    absolute top-0 left-0 right-0 
    min-h-screen
    md:relative md:flex md:flex-row 
    md:py-0 md:p-0 
    md:min-h-0 md:h-auto md:bg-transparent; */

	/* md:justify-end; */
	transform: translateY(-100%);
	z-index: var(--zi-nav);
	/* grid-area: 'links'; */
}
#nav.toggled .links {
	transform: translateY(0%);
}

#nav .links li {
	@apply font-adelez opacity-0 delay-[250ms];
	transition: transform 360ms, opacity 360ms;
	transform: translateY(-300%);
	/* font-family: var(--font-adelez); */
	/* opacity: 0; */
	/* transition-delay: 250ms; */
}

#nav .links li:nth-child(2) {
	transform: translateY(-250%);
	transition-delay: 200ms;
}

#nav .links li:nth-child(3) {
	transform: translateY(-200%);
	transition-delay: 150ms;
}

#nav .links li:nth-child(4) {
	transform: translateY(-150%);
	transition-delay: 100ms;
}

#nav .links li:nth-child(5) {
	transform: translateY(-100%);
	transition-delay: 50ms;
}

#nav.toggled .links li {
	transform: translate(0%, 0%);
	opacity: 1;
	transition-timing-function: ease-out;
	pointer-events: all;
}
#nav .links li a {
	@apply inline-flex font-bold rounded-md text-2xl md:text-lg my-4 p-4 mx-auto;
	/* color: #000; */
	transition: 0.5s;
	border-radius: 10px;
}
#nav .links li:hover a {
	color: #fff;
	background: #000;
	/* opacity: 0.7; */
}
#nav .divider {
	@apply fixed top-0 opacity-0 invisible;
	transition: transform 200ms;
	transform: translateY(-100%);
}
#nav .divider .wave {
	transform: translateY(-99%);
	/* transform: rotateY(180deg); */
}
#nav.toggled .divider {
	@apply md:opacity-100 md:visible;
	border-top: calc(var(--nav-height) / 1.5) solid var(--bg-main);
	transform: translateY(0);
}
#nav.toggled .divider .wave {
	transform: translateY(-1%);
}

@media only screen and (min-width: 768px) {
	#nav {
		/* grid-template-areas: 'links logo menu'; */
		/* border-bottom-left-radius: 50px;
		border-bottom-right-radius: 50px; */
		/* flex-wrap: nowrap;
		flex-direction: row;
        */
		justify-content: end;
		align-content: center;
		align-items: center;
	}
	#nav .links {
		justify-content: space-around;
		/* padding-right: 3rem;
		padding-left: 4rem; */
		width: auto;
	}
	#nav .links li {
		@apply hover-underline-animation;
	}
	#nav .links li a {
		@apply m-0 p-0;
		/* padding: 10px;
		margin: 10px; */
	}
	#nav .links li:nth-child(5) {
		transform: translateX(100%);
	}

	#nav .links li:nth-child(4) {
		transform: translateX(150%);
	}

	#nav .links li:nth-child(3) {
		transform: translateX(200%);
	}

	#nav .links li:nth-child(2) {
		transform: translateX(250%);
	}

	#nav .links li:nth-child(1) {
		transform: translateX(300%);
	}

	#nav.toggled .links li {
		transform: translateX(0%);
	}
	#nav .links li:hover a {
		background: none;
		color: inherit;
		/* transform: translate(3%, 3%); */
	}
}
/* nav end */
/* trying to fix sticky header and footer TODO later */
/* #root {
	display: flex;
	position: relative;
	min-height: 100vh;
} */
/* hero */
header {
	height: var(--hero-height);
	height: calc(var(--vh, 1vh) * 90);
	/* overflow-x: hidden; */
	/* overflow: visible; */
}
header > div {
	@apply z-hero h-hero;
	/* z-index: calc(var(--zi-hero)); */

	/* height: var(--hero-height); */
}
header > div .overlay_half,
header > div .overlay_main {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: calc(var(--zi-hero) - 3);
	background: rgba(45, 45, 51, 0.5);
}
header > div .overlay_half {
	z-index: calc(var(--zi-hero) - 1);
	background: linear-gradient(180deg, rgba(45, 45, 51, 0) 0%, rgba(45, 45, 51, 0) 60%, rgba(45, 45, 51, 0.5) 80%, rgba(17, 17, 19, 1) 100%);
}

header .images {
	/* overflow: hidden; */
	height: var(--hero-height);
	z-index: calc(var(--zi-hero) - 4);
}
header .text {
	z-index: calc(var(--zi-hero) - 2);
}
header img {
	height: 100%;
	width: 100%;
	max-width: 60vw;
	max-height: 60vh;
	object-fit: cover;
	object-position: center center;
}
/* header .stick {
	position: fixed;
} */

header .divider {
	/* position: absolute;
	top: var(--hero-height); */
	fill: rgba(17, 17, 19, 1);
}
/* end hero */

/* about */
#about {
	/* padding-top: var(--divider-size); */
	padding: auto;
	z-index: var(--zi-about);
	/* this causes the divider to be split from the next section on bigger screens */
	/* min-height: 90vh; */
}
/* #about .divider {
	fill: rgba(17, 17, 19, 0.9);
} */
#about .doodleOnTitle {
	/* size of text-3xl */
	--about-title: 128px;
	/* @apply text-9xl; */
	margin-top: var(--about-title);
}
#about .doodleOnTitle .title {
	/* font-size: var(--about-title); */
	font-size: calc((var(--about-title) / 2));
	line-height: 1;
}
#about .doodleOnTitle .doodle svg {
	width: var(--about-title);
	height: auto;
}
#about .doodleOnTitle .doodle {
	position: absolute;
	left: calc(0px - (var(--about-title) / 10));
	top: calc(0px - var(--about-title) - (var(--about-title) / 15));
	z-index: 2;
	fill: var(--c-cta-border);
}

#about .textAndProfile {
	flex-basis: 100%;
}
#about img {
	/* shape-outside: circle(50%); */
	shape-outside: circle();
	float: left;
}

/* end about */

/* gallery */
#gallery {
	/* --deck-item-height: 50vh; */
	/* padding-top: var(--divider-size); */
	/* background: var(--bg-main); */
	z-index: var(--zi-about);
}
/* #gallery section { */
/* @apply relative w-full h-full pt-12 flex-to-center; */
/* scroll-snap-align: center; */
/* position: relative; */

/* border-bottom: 10px solid rgb(68, 0, 255); */
/* 
	padding: 1rem;
	min-width: 100vw;
	min-height: 100vh;
	scroll-snap-align: start;
	text-align: center;
	position: relative;
	display: flex;
	justify-content: center; */
/* } */
#gallery a.cta {
	@apply text-left p-3 m-3 font-adelez bg-cta rounded-md text-lg text-white;
	/* font-family: var(--font-adelez); */
	/* background-color: var(--c-cta); */
}
#gallery a.more {
	@apply text-right ml-auto mr-4 mt-8 p-1 rounded-md text-xs text-white;
}
#gallery .slider * {
	z-index: var(--zi-gallery);
}
#gallery .slider .btn {
	@apply fixed p-2 w-16 h-16 opacity-80 rounded-full pointer-events-auto;
	background: #2f0c0a;
	fill: var(--bg-main);
	z-index: var(--zi-gallery-controls);
}
#gallery .slider .btn:hover {
	@apply opacity-100;
	/* 
	color: #40778b;
	fill: var(--bg-main); */
}
#gallery .slider .bg {
	z-index: var(--zi-gallery-bg);
}
#gallery .slider img {
	/* @apply m-16 w-8 h-96; */
	background: none;
	/* fill: var(--bg-main); */
	z-index: var(--zi-gallery);
}
/* #gallery > ul {
	padding: 1em;
} */
.shine-effect {
	@apply overflow-hidden relative;
}

.shine-effect::before {
	content: '';
	transform: skewX(-45deg);
	background-image: linear-gradient(90deg, rgb(238, 238, 238, 0) 0px, rgba(255, 255, 255, 0.4) 100px, rgb(238, 238, 238, 0) 200px);
	position: absolute;
	background-repeat: repeat-x;
	left: -100%;
	width: 200px;
	height: 100%;
	-webkit-animation: shine 4s linear 1s infinite;
	animation: shine 4s linear 1s infinite;
}

@-webkit-keyframes shine {
	40%,
	100% {
		left: 200%;
	}
}

@keyframes shine {
	40%,
	100% {
		left: 200%;
	}
}
#gallery #galleryNav li {
	@apply font-adelez;
	/* @apply text-lg px-4 text-red; */
	/* font-family: var(--font-adelez); */
	/* @apply px-4 hover:cursor-pointer hover:text-doodle_dark; */
}

#tier-buttons {
	@apply bg-transparent sticky bottom-0 right-0 w-full
    flex flex-nowrap
    items-end overflow-x-scroll
    h-[30vh];
	/* height: 10%; */
}

.tier-button {
	@apply relative 
    ease-linear
    transition-all
    items-end
    justify-between overflow-hidden 
    max-h-[10vh]
    h-[10vh] /*this makes the image centered*/
    min-w-[150px] max-w-[200px]
    w-full
    my-0 mx-1
    outline-none
    flex rounded-lg
    hover:outline-none 
    hover:h-full
    hover:max-h-[30vh];
}
.tier-button:nth-child(1) {
	margin-left: auto;
}
.tier-button:nth-child(1).shine-effect::before {
	animation-delay: 0.5s;
}
.tier-button:nth-child(2).shine-effect::before {
	animation-delay: 1s;
}
.tier-button:nth-child(3).shine-effect::before {
	animation-delay: 1.5s;
}
.tier-button:nth-child(4).shine-effect::before {
	animation-delay: 2s;
}
.tier-button:nth-last-child(1) {
	margin-right: auto;
}
.tier-button.selected {
	@apply /* h-full max-h-[30vh] */
    border-b-4 border-orange;
	z-index: 2;
}
.tier-button.inview {
	@apply h-full max-h-[30vh];
	/* z-index: 2; */
}
[data-section='Testimonies'] .tier-button {
	@apply ease-linear h-full max-h-[30vh];
}
.tier-button img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.tier-button .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.4);
	color: #fff;
	font-size: 1.5rem;
	font-weight: bold;
	text-transform: uppercase;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}
.tier-button:hover .overlay {
	opacity: 1;
}
/* end gallery */

/* testimonies */
/* #testimonies > div {
	@apply max-w-[95vw] md:max-w-xl lg:max-w-2xl;
	z-index: var(--zi-testimonies);
	background: var(--c-offwhite);
} */
/* [data-section='Gallery'] > #root #testimonies,
[data-section='Testimonies'] > #root #testimonies,
[data-section='Specials'] > #root #testimonies {
	position: sticky;
	top: var(--nav-height);
	top: calc(var(--nav-height) + 1rem);
} */
/* .testimony.active {
	display: flex;
} */
/* .swiper-button-next,
.swiper-button-prev {
	color: var(--c-red-orange);
}*/
.pagination {
	@apply bottom-0 w-full left-0;
	/* padding-top: 5vh;
	bottom: 0; */
}
/* .swiper-pagination-bullet {
	@apply bg-offwhite border-2 border-red opacity-100 rounded-full;
}
.swiper-pagination-bullet-active {
	@apply bg-red border-offwhite
} */
.fullscreen-testimony {
	@apply fixed top-0 left-0 h-screen w-screen z-50 flex items-center justify-center select-none;
}
.testimony img {
	@apply block m-auto rounded-full object-cover object-top animate-testimonyHide select-none;
	height: 100px;
	width: 100px;
}
.testimony h3 {
	@apply font-bold mx-4 text-lg py-4 my-auto animate-testimonyContentOut;
	color: var(--c-red-orange);
}
.testimony p {
	@apply m-auto text-left align-middle pb-4 px-5 animate-testimonyContentOut;
	/* height: 80px; */
	/* overflow-y-scroll */
}
.testimony a {
	@apply cursor-pointer animate-testimonyContentOut opacity-70 px-5 mt-auto hover:opacity-100 text-right;
	/*   */
	/* margin-left: 40vw; */
	/* margin-bottom: 2rem; */
}
.testimony.active img {
	@apply animate-testimonyShow;
}
.testimony.active h3,
.testimony.active p,
.testimony.active a {
	@apply animate-testimonyContentIn;
}

#testimonies .testimony {
	@apply flex flex-col items-center h-full justify-center overflow-hidden;
}
#testimonies .testimonials-container {
	position: relative;
	display: flex;
	align-items: center;
	max-width: 772px;
	margin: 0 auto;
	overflow: hidden;
	inset: 0;
}
#testimonies .nav-button {
	@apply /*  top-1/2 transform -translate-y-1/2 */
    w-10 bg-transparent absolute z-50 hover:outline-none hover:bg-orange hover:bg-opacity-50 h-full text-white;
}
#testimonies .nav-button svg {
	@apply w-10 h-auto touch-none pointer-events-none;
}
/* end testimonies */

/* start specials */
#specials {
	@apply relative min-h-full h-auto;
	background-color: var(--c-red-orange);

	z-index: var(--zi-specials);

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/* font-size: calc(10px + 2vmin); */

	/* position: relative;
	min-height: 100vh; */
}
/* #specials .doodles {
	display: flex;
	flex-wrap: wrap;
} */
#specials .doodle {
	/* @apply flex-to-center; */
	/* height: max-content; */
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	position: relative;
	/* padding: 0.5rem; */
}
#specials .doodle.fullbody,
#specials .doodle.oneplus {
	/* flex-basis: 50%; */
	max-width: 50%;
	align-self: flex-end;
	/* flex-grow: 20%; */
}
#specials .doodle.custom {
	/* flex-basis: 100%; */
	width: 100%;
	/* max-width: 60vw; */
	/* flex-grow: 100%; */
}

#specials h3 {
	@apply font-adelez;
	/* font-family: var(--font-adelez); */
}

#specials .divider .wave svg {
	fill: var(--bg-main);
}

#specials img {
	padding: 0.5rem;
	width: 100%;
	height: auto;
	filter: invert(); /*TODO temp until i have proper svgs*/
}

/* end specials */

/* start tiers */
#tiers {
	/* @apply pb-10; */
	position: relative;
	/* min-height: 100vh; */
}
#tiers .divider .wave svg {
	fill: var(--c-red-orange);

	/* width: 100%; */
}

/* #tiers div table {
	position: relative;

	min-width: 580px;
	overflow-x: scroll;
} */
/* min-width: var(--mobile-breakpoint); */

#tiers .tier-name {
	@apply font-adelez pb-2;
}
/* #tiers .most-popular { */
/* @apply absolute text-offwhite left-1/2 -top-4 font-bold bg-cyan-500 py-1 px-2 rounded text-xs; */
/* transform: translateX(-50%); */
/* top: -2rem; */
/* left: 50%; */
/* } */

#tiers .price svg {
	@apply w-12 h-12 m-auto;
}
#tiers .tier-row svg#price_bubble path {
	fill: var(--c-bluish);
}
#tiers .tier-row svg#price_bubble text {
	fill: var(--c-offwhite);
	content: '$$$';
}

/* #tiers .tier-row:hover svg#price_bubble path {
	color: var(--c-bluish);
} */
#tiers .tier-row:hover svg#price_bubble text {
	fill: var(--c-bg_main);
	@apply after:content-['+'];
}

#tiers .image {
	max-height: 15rem;
	max-width: 15rem;
	min-height: 5rem;
	min-width: 5rem;
	overflow: hidden;
}

#tiers .tier-comparison-table img {
	@apply rounded-md h-full w-full object-cover;
	aspect-ratio: 1;
}

#tiers .tier-comparison-table {
	@apply px-6 w-full h-full flex flex-nowrap overflow-x-scroll self-center;
}
/* this makes sure that the table is centered */
#tiers .tier-comparison-table .tier-row:nth-child(1) {
	margin-left: auto;
}
#tiers .tier-comparison-table .tier-row:nth-last-child(1) {
	margin-right: auto;
}

#tiers .tier-row {
	@apply rounded-md 
    max-w-sm 
    flex flex-col items-center
    p-5 relative mx-2 my-12 
    justify-between 
    border-2 border-solid border-doodle
    bg-main hover:bg-opacity-50 
    hover:scale-105
    ease-in-out;
	flex: 0 0 45%;
	/* border: 2px solid #ccc; */
	transition: all 200ms ease-in-out;
	/* min-width: 35vw; */
	max-width: fit-content;
	/* max-height: 95vh; */
	justify-content: space-between;
	/* align-items: stretch; */
}

#tiers .tier-name {
	/* text-align: center; */
	font-weight: bold;
}

#tiers .most-popular {
	display: inline;
}

#tiers .description {
	@apply mb-auto;
	/* text-xs sm:text-sm md:text-base; */
	/* margin-top: auto; */
	/* margin-bottom: auto;
	text-align: center; */

	/* display: flex;
	flex-wrap: wrap; */
}

#tiers .features {
	@apply mt-auto mb-0 list-none text-left p-0 justify-self-end 
    text-xs sm:text-sm pt-4;
	/* list-check */
	/* list-style: none; */
	/* margin: 0; */
	/* padding: 0; */
	/* display: flex;
	flex-wrap: wrap; */
	/* justify-self: flex-end; */
}
#tiers .checkmark {
	@apply text-green mr-2;
	/* color: var(--c-green); */
}

#tiers .price {
	justify-self: flex-end;

	/* margin-top: auto; */
	text-align: center;
}

#tiers .button-container {
	justify-self: flex-end;
	/* margin-top: auto; */
	display: flex;
	justify-content: center;
}

#tiers a {
	@apply hover:outline-doodle inline-block 
    border-none rounded
    bg-doodle text-main cursor-pointer
    py-2 px-4 
    /* my-4 */
    font-bold 
    appearance-none select-none
    duration-200 ease-in-out transition-all;
	/* transition: all 200ms ease-in-out; */
	/* align-self: center; */
	/* text-decoration: none; */

	/* -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; */
	/* line-height: initial; */
	/* -webkit-touch-callout: none;
	-webkit-user-select: none;
    user-select: none; */
}
#tiers .tier-row:hover a {
	@apply bg-main text-doodle outline outline-doodle outline-4 rounded-md;
}
/* end tiers */

/* start faq */
#testimonies .title {
	@apply text-offwhite text-center pb-4 text-xl bg-transparent;
}
#faq .faq {
	@apply my-4 mx-3 md:mx-2 p-4 md:p-3 bg-main rounded-lg shadow drop-shadow-md hover:shadow-orange;
}
#faq .title {
	@apply text-offwhite text-center bg-transparent;
}
#faq .faq-question {
	/* position: relative; */
	/* font-size: calc(var(--f-txt) + 0.3vmin); */
	/* padding-right: 20px; */
	transition: all 0.4s ease;
}
.faqs .faq .faq-question::after {
	font-weight: 700;
	content: '+';
	position: absolute;
	top: 0;
	right: 5px;
	transition: all 0.2s ease;
}

#faq .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	font-size: var(--f-txt);
	overflow: hidden;
	transition: all 0.2s ease;
	/* white-space: pre-line; */
}
#faq .faq .faq-answer a {
	/* text-decoration: underline; */
	cursor: pointer;
	color: var(--c-red-orange);
}
#faq .faq .faq-answer a:hover {
	/* opacity: 0.7; */
	text-decoration: underline;
}
#faq .faq.open .faq-question {
	margin-bottom: 15px;
}

#faq .faq.open .faq-question::after {
	content: '-';
}

#faq .faq.open .faq-answer {
	max-height: 150px;
	overflow-y: scroll;
	opacity: 1;
}

/* this section doesnt get bigget then 768px, so this is the only query we need */
@media only screen and (min-width: 480px) {
	#faq .faq .faq-question::after {
		right: 10px;
	}
}

#faq .divider {
	@apply fill-offwhite;
}

/* end faq */

/* start contact */
#contact * {
	@apply transition-all duration-200;
}
#contact .form_section {
	/* todo maybe change md: which is 768px to 992px or something else*/
	@apply flex flex-col items-start md:w-4/6 w-11/12 mr-0 md:mr-5 max-w-2xl;
	/* max-width: 600px; */
	/* TODO need to find a way to handle this in a fluid responsive way, instead of forcing the screen to be bigger then 300px */
	min-width: 300px;
}

/* #contact .doodle { */
/* @apply ; */
/* z-index: 1;  */
/* max-width: 400px; */
/* min-width: 200px; */
/* } */

/* basic sizing for the doodle, changing the doodle div size will control its size */
#contact .doodle svg {
	@apply fill-contact w-full h-full;
	/* fill: var(--c-doodle-contact);
	width: auto;
	height: 100%; */
	max-height: clamp(20vh, 400px, 50vh);
	max-width: clamp(40vw, 200px, 80vw);
}
/* #contact .doodle { */
/* @apply flex self-end justify-self-end bottom-0 right-0 sticky; */
/* display: flex;
	align-self: flex-end;
	justify-self: flex-end; */

/* position: sticky;
	right: 0;
	bottom: 0; */
/* } */

/* #contact button.loading {
} */
/* end contact */

/* start footer */

/* footer > div {
	@apply px-4 m-auto flex flex-col items-stretch justify-around flex-wrap overflow-hidden;
	align-content: stretch;
} */
/* footer .social_share {
	@apply flex justify-center m-4 p-4 overflow-hidden text-2xl;
}
footer .social_share a:hover {
	opacity: 0.7;
}
footer .social_share svg {
	margin: 0 10px;
	cursor: pointer;
} */

footer {
	@apply bg-bg_dark flex-wrap flex flex-col h-full text-center items-center justify-between pointer-events-auto;
	transition: all 0.5s ease-in-out;
	color: white;
	min-height: 15vh;
	/*
    padding-top: calc(var(--divider-size) / 2); 
    padding-top: var(--divider-size); */
	/* dont put max value, instead shrink the doodle
    max-height: vh; */
	/* display: flex; */
	/* flex-wrap: wrap; */
	/* flex-direction: column; */
	/* align-items: center; */
	/* justify-content: space-between; */
	/* height: 100%; */
}

footer svg {
	transition: transform 0.2s ease-in-out;
}

footer svg:hover {
	transform: scale(1.5);
	opacity: 0.7;
	pointer-events: all;
}
footer .social-buttons {
	/* place the social buttons on the top with some witty text above it (i think), on small screens, on bigger screens I leave it up to you */
	order: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
footer .img {
	/* place the doodle on the right on bigger screens */
	order: 1;
	/* transform: rotateY(180deg); */
}
footer .img .doodle {
	fill: white;
}
footer .copyright {
	/* place the copyright in the bottom on big screens, either in the middle or to the left */
	order: 3;
	text-align: left;
}

/* on smaller screens, center the image and stack the social buttons and copyright vertically */
@media (max-width: 768px) {
	/* footer .img {
		order: 0;
	} 
    footer .copyright,
	footer .social-buttons {
		order: 1;
	} */
	footer {
		flex-direction: column;
	}
}
/* end footer */
