.deck-container {
	@apply z-10 relative mx-auto min-h-[50vh] w-[70vw] md:w-[50vw] md:h-[50vh] lg:w-[30vw] lg:h-[40vh] max-h-[1000px];
}
.deck-wrapper {
	@apply py-8 absolute h-full hover:drop-shadow-xl;
}
/* .gallery-image-wrapper {
	@apply relative w-auto w-full max-h-[50vh] h-fit touch-none select-none bg-transparent bg-cover bg-no-repeat bg-center;
} */
.gallery-image {
	@apply max-w-full max-h-full touch-none select-none bg-transparent bg-cover bg-no-repeat bg-center rounded-xl;
}

#gallery div[style*='width: 100%'] {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
