.orderSection {
	@apply pt-8;
}
ul {
	@apply pt-4;
}
#order .text-window {
	@apply rounded-md max-w-full 
	px-3 py-2
	mx-4 mt-2 
	appearance-none 
	caret-black
	font-regular text-lg focus:outline-orange outline-offset-4;

	-webkit-appearance: none;
	-moz-appearance: none;
}
#order .text-window.valid {
	@apply outline-green;
}
#order .text-window.invalid {
	@apply outline-red;
}
.orderOption {
	@apply w-full px-2 mb-2 rounded-lg transition-all duration-[200ms];
}
.orderOption.selected {
	@apply /* outline outline-orange outline-offset-2 */
	 bg-orange bg-opacity-10;
}
.orderOption:hover {
	@apply /* bg-orange bg-opacity-10 */
	outline outline-orange outline-offset-2;
}
#order .exampleImage {
	@apply rounded-lg my-2 mr-2 aspect-square object-left-top object-cover w-24 sm:w-32;
}
#order .shine-effect::before {
	animation-duration: 8s;
	animation-delay: 3s;
}

/* #order .animated-container {
	@apply before:content-["$"];
} */
#order .wait-option {
	@apply max-w-full flex my-2 mx-4 rounded-lg border-2 cursor-pointer appearance-none 
	outline-none hover:border-blue-400 border-gray-300;
}
#order .wait-option.selected {
	@apply border-blue-500 bg-blue-100 bg-opacity-50;
}

/* #order button {
	@apply hover:outline-none rounded-md bg-blue-400 text-white;
} */
